.app {
  background: #F7F7F7;
  height: 100vh;
  min-height: 100vh;
  .site-layout {
    max-height: 100vh;
    // overflow-x: hidden;
    overflow-y: auto;
    // .app_content {
    //   padding: 1.5rem;
    //   width: 100%;
    //   max-width: 87rem;
    //   margin: 0 auto;
    //   &>*{
    //     padding-bottom: 1.25rem;
    //   }
    // }
  }
} 
