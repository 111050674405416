@import "./variables.less";

.header_bar {
  position: sticky;
  top: 0;
  z-index: 1000;
  .ant-layout-header {
    height: 5.125rem;
    // width: calc(100% - 3rem);
    // max-width: 84rem;
    margin: 0 auto 0;
    // border-radius: 0.375rem;
    padding: 0 1.5rem !important;
    background-color: #F7F7F7 !important;
    // box-shadow: 0 0.125rem 0.25rem #a5a3ae4d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header_lt {
      display: flex;
      align-items: center;
      &>* + * {
        margin-left: 1.5rem;
      }
      .anticon {
        font-size: 1rem;
      }
      .header_prompt_btn {
        height: 1.875rem;
        padding: 0 .5rem;
        font-size: .75rem;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        border-radius: 6px;
      }
      .header_title {
        display: flex;
        align-items: center;
        .title_item {
          display: flex;
          align-items: center;
          .title_text {
            cursor: pointer;
            margin-left: .5rem;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .title_0 {
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.125rem;
          color: #3D3D3D;
          .title_text {
            margin-left: 0;
          }
        }
        .title_1 {
          font-size: 1.125rem;
          font-weight: normal;
          line-height: 1.125rem;
          color: #666666;
          margin-left: .5rem;
        }
      }
    }
    .header_gt {
      display: flex;
      align-items: center;
      .add_btn {
        margin-right: 1.5rem;
      }
      .header_language {
        cursor: pointer;
        margin-right: 1.5rem;
        line-height: 2;
        .anticon {
          font-size: .75rem;
          margin-left: .25rem;
        }
      }
    }
  }
}

@media ( max-width: 768px ) {
  .header_bar {
    .ant-layout-header {
      height: 4rem;
      .header_gt {
        .header_divider {
          display: none;
        }
        .header_type {
          padding: 0;
          .ht_title {
            font-weight: 500;
          }
          .ht_select {
            margin-top: 0.25rem;
            display: flex;
            flex-wrap: nowrap;
          }
        }
        .add_btn {
          margin-right: 1.5rem;
        }
        .header_language {
          cursor: pointer;
          margin-right: 1.5rem;
          .anticon {
            font-size: .75rem;
            margin-left: .25rem;
          }
        }
      }
    }
  }
}

.hd_form {
  &>div {
    display: block;
  }
  .ul_form_title {
    font-size: .875rem;
    margin: .75rem 0 .5rem;
  }
  .ul_form_info {
    font-size: .75rem;
    color: red;
  }
}