/* global.css */
iframe#webpack-dev-server-client-overlay {
  display: none;
}
/* 重置 body 和 html 元素的样式 */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 0.9375rem;
  line-height: 1.47;
  min-height: 100vh;
}
/*部分标签全局样式控制*/
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}
ul,
ol {
  list-style-type: none;
}
img {
  max-width: 100%;
  height: auto;
}
i {
  font-style: normal;
}
button:focus {
  outline: none !important;
}
/* 设置全局链接的颜色和样式 */
a {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}
*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0.3125rem;
  height: 0.3125rem;
}
*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 1rem;
  background: #ccc;
}
*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  display: none;
}
/* 其他全局样式规则 */
/* ... */
.ant-layout {
  background-color: #F7F7F7 !important;
}
.anticon {
  vertical-align: middle;
}
.ant-btn {
  padding: 0.6rem 1rem;
  font-size: 0.9375rem;
  line-height: initial;
  height: unset;
  border-radius: 0.375rem;
  font-weight: 500;
  outline: unset !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-btn.origen {
  color: #fff;
  background-color: var(--sub-color);
  border-color: var(--sub-color);
}
.ant-btn.origen:hover {
  color: #fff !important;
  background-color: var(--sub-color) !important;
  border-color: var(--sub-color) !important;
}
.ant-btn.origen:active {
  scale: 0.98;
  background-color: var(--sub-color) !important;
  border-color: var(--sub-color) !important;
}
.ant-btn.middle {
  padding: 0.25rem 0.9375rem;
}
.ant-btn .ant-btn-icon {
  vertical-align: middle;
}
.ant-btn[disabled] {
  color: #B7B7B7 !important;
  border-color: #D9D9D9 !important;
  background-color: #F5F5F5 !important;
}
.ant-btn[disabled]:hover,
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus {
  color: #B7B7B7 !important;
  border-color: #D9D9D9 !important;
  background-color: #F5F5F5 !important;
}
.ant-popover-content .ant-btn,
.ant-modal-content .ant-btn {
  display: inline-block;
}
.ant-popover-content .ant-modal-body,
.ant-modal-content .ant-modal-body {
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
}
.ant-btn-lg {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
}
.ant-popover {
  z-index: 2023 !important;
}
.header_dropdown {
  margin: -12px;
  padding: 0.25rem 0;
  width: 14rem;
  color: #5D596C;
  font-size: 0.9375rem;
}
.header_dropdown .hd_header {
  display: flex;
  margin-bottom: -0.25rem;
}
.header_dropdown .hd_header .person_box {
  margin-right: 1.25rem;
}
.header_dropdown .hd_header .person_name {
  font-weight: 600;
}
.header_dropdown .hd_line {
  border-top: 1px solid #dbdade;
  margin: 0.5rem 0;
}
.dropdown_btn {
  line-height: 1.375;
  width: calc(100% - 1rem);
  margin: 0.25rem 0.5rem;
  padding: 0.42rem 1rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown_btn:hover {
  color: var(--primary-color);
  background-color: var(--primary-color-18);
}
.dropdown_btn .anticon {
  font-size: 1.125rem;
  margin-right: 0.5rem;
}
.person_box {
  position: relative;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EBF4FF;
  color: var(--primary-color);
  user-select: none;
  cursor: pointer;
}
.ul_form > div {
  display: block;
}
.ul_form .ul_form_title {
  font-size: 0.875rem;
  margin: 0.75rem 0 0.5rem;
}
.ul_form .ul_form_info {
  font-size: 0.75rem;
  color: red;
}
.ant-tabs .ant-tabs-nav {
  margin-bottom: 0;
  border-bottom: 1px solid #E6E6E6;
}
.ant-tabs .ant-tabs-tab {
  padding: 0.625rem 0.625rem 0.9375rem;
  color: #666666;
}
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 1.25rem;
}
.sx_search .search_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sx_search .search_btn .anticon-search {
  margin-right: 0.375rem;
}
.text_omitted {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
