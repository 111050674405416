@import "./variables.less";

.navigation {
    min-height: 100vh;

    .ant-layout-sider {
        height: 100vh;
        max-height: 100vh;
        box-shadow: 0 0.125rem 0.25rem #a5a3ae4d;
        user-select: none;
        padding: 0 .9375rem;

        .ant-layout-sider-children {
            display: flex;
            flex-direction: column;
        }

        &.ant-layout-sider-collapsed {
            padding: 0;
        }
        // 修改菜单样式
        .ant-menu-root {
            flex: 1;
            overflow: hidden;
            // 一级菜单间距
            & > * + * {
                margin-top: 1rem !important;
            }

            &.ant-menu-inline-collapsed {
                // 折叠后一级图标
                .ant-menu-item, .ant-menu-submenu-title {
                    padding: 0 0 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .ant-menu-item-icon {
                        width: 1.5rem;
                        min-width: 1.5rem;
                        justify-content: center;
                    }

                    .ant-menu-title-content {
                        display: none;
                    }
                }
            }

            .ant-menu-submenu-title {
                margin: 0;
                width: 100%;
                padding: 0 !important;
                border-radius: .625rem !important;

                &:active, &:hover {
                    // background: rgba(255, 255, 255, 0.1) !important;
                    background: unset !important;
                    color: var(--nav-text-hover) !important;
                }
            }

            .ant-menu-sub.ant-menu-inline {
                background: var(--nav-bg) !important;
                position: relative;

                &::after {
                    position: absolute;
                    content: '';
                    height: calc(100% + .5rem);
                    left: .625rem;
                    top: -0.25rem;
                    width: .0625rem;
                    background: rgba(255, 255, 255, 0.1);
                    ;
                }

                & > .ant-menu-item-selected {
                    position: relative;

                    &::after {
                        position: absolute;
                        content: '';
                        width: .125rem;
                        height: 1.25rem;
                        border-radius: .0625rem;
                        background: var(--primary-color);
                        left: .625rem;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            // 所有item
            .ant-menu-item {
                // margin: .125rem .875rem;
                // width: calc(100% - 1.75rem);
                margin: 0;
                width: 100%;
                padding: 0 0 0 2.125rem !important;
                border-radius: .625rem !important;
                // color: rgba(255, 255, 255, 0.5);
                &:active, &:hover {
                    color: var(--nav-text-hover) !important;
                    background: rgba(255, 255, 255, 0.1) !important;
                }
            }
            // 一级item
            & > .ant-menu-item {
                padding: 0rem !important;
                background: unset !important;

                &:active, &:hover {
                    background: unset !important;
                    // color: var(--primary-color)  !important;
                }
            }
            // 选中
            .ant-menu-submenu-selected {
                & > .ant-menu-submenu-title {
                    // color: rgba(0,0,0,0.88) !important;
                    color: var(--primary-color) !important;
                    font-weight: 600;
                    // background: #f8f8f8 !important;
                }
            }

            .ant-menu-item-selected {
                font-weight: 600;
                background: rgba(255, 255, 255, 0.1) !important;
                color: var(--nav-text-hover) !important;
            }

            & > .ant-menu-item-selected {
                color: var(--primary-color) !important;
                background: unset !important;

                &:active, &:hover {
                    color: var(--primary-color) !important;
                    background: unset !important;
                }
            }

            .leadIc {
                font-size: 1.125rem;
            }
        }

        .logo_box {
            height: 5.125rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon_box {
                overflow: hidden;
                cursor: pointer;
                display: flex;
                align-items: center;
                .logo { width: 160px; max-width: 160px; min-width: 160px; }
            }

            .nav_close {cursor: pointer;}

            &.collapsed {
                max-width: 40px;
                width: fit-content;
                margin: 0 auto;
                padding: 0;
                // justify-content: center;
                .icon_box {
                    min-width: 1.25rem;
                    max-width: 1.25rem;
                }

                .nav_close {
                    display: none;
                }
            }

            .anticon {
                display: none;
                font-size: 1rem;
                color: rgba(255, 255, 255, .38);
            }
        }

        .logo_box_apm {
            margin: 2.875rem auto 1.875rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon_box {
                overflow: hidden;
                cursor: pointer;
                display: flex;
                align-items: center;
                .logo { width: 160px; max-width: 160px; min-width: 160px; }
            }

            .nav_close {cursor: pointer;}
            &.collapsed {
                max-width: 40px;
                width: fit-content;
                margin: 0 auto;
                padding: 0;
                // justify-content: center;
                .icon_box {
                    min-width: 1.25rem;
                    max-width: 1.25rem;
                }

                .nav_close {
                    display: none;
                }
            }

            .anticon {
                display: none;
                font-size: 1rem;
                color: rgba(255, 255, 255, .38);
            }
        }
    }

    .ant-layout {
        background: unset;
    }
    .nav_power {
        font-size: .875rem;
        margin-top: .5rem;
        margin-left: .1875rem;
        color: var(--power-color);

        a {
            color: var(--power-link-color);
        }
    }
}

.navigation.mce {
  .ant-layout-sider {
    .logo_box {
      height: unset;
      .icon_box {
        width: 100%;
        .logo {
          width: 200px;
          max-width: 200px;
          min-width: 200px;
          margin: 32px auto 20px;
        }
      }
    }
  }
}

@media (max-width: 1200px)  {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    transition: all .5s;
    &[open]{
      width: 100vw;
      // background: #4b465c7f;
    }
    .ant-layout-sider {
      // 修改菜单样式
      .ant-menu {
        // height: calc(100vh - 19rem);
      }
    }
  }
}